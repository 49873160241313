.App {
  text-align: center;
  color: white;
  background-color: #11698e;
  min-height: 100vh;
}

.App-header {
  background-color: #11698e;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(41, 105, 24);
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 767px) {
  input {
    font-size: 16px;
  }
}
