.BlankPlates {
  border: dotted 5px white;
  height: 200px;
  text-align: center;
  justify-content: center;
  font-size: 26px;
  display: block;
  position: relative;
}

.SmallDisclaimer {
  font-size: 14px;
}

.BlanksCentered {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
