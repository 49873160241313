.WeightDisplayContainer {
  position: relative;
}

.Bar {
  height: 28px;
  width: 90%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: start;
  display: flex;
  position: absolute;
  top: 43%;
  flex-direction: row;
  z-index: 0;
}

.Knurling {
  height: 12px;
  width: 80px;
  background-color: inherit;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid black;
}

.Collar {
  background-color: inherit;
  width: 20px;
  height: inherit;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid black;
}

.Sleeve {
  background-color: inherit;
  width: 80%;
  height: 18px;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid black;
  text-align: right;
}

.Kg25 {
  background-color: rgb(83, 83, 83);
}

.Kg20 {
  background-color: rgb(194, 194, 194);
  color: black;
}

.Kg15 {
  background-color: rgb(194, 194, 194);
  color: black;
}

.Lbs45 {
  background-color: rgb(119, 118, 118);
}

.Knurling.Kg15 {
  height: 10px;
}

.Knurling.Kg25 {
  height: 14px;
}
