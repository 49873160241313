.Plate {
  width: 20px;
  height: 200px;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid black;
  z-index: 1;
}

.Kg25 {
  background: linear-gradient(#e70606, #fc0808);
  width: 25px;
}

.Kg20 {
  background: linear-gradient(#0408f4, #2522d6);
  width: 20px;
}

.Kg15 {
  background: linear-gradient(#e2e60e, #ffdd1c);
  color: black;
  width: 19px;
}

.Kg10 {
  background: linear-gradient(#15e602, #08ff3d);
  width: 18px;
}

.Kg5 {
  background: linear-gradient(#ffffff, #afafaf);
  height: 125px;
  color: black;
}

.KgTwoPointFive {
  background: linear-gradient(#e70606, #fc0808);
  height: 100px;
}

.KgOnePointFive {
  background: linear-gradient(#e2e60e, #ffdd1c);
  color: black;
  height: 90px;
}

.KgOnePointTwoFive {
  background: linear-gradient(#9f0ff3, #ca1cff);
  height: 100px;
}

.Kg1 {
  background: linear-gradient(#15e602, #08ff3d);
  height: 80px;
  width: 15px;
}

.KgPointFive {
  background: linear-gradient(#ffffff, #afafaf);
  height: 70px;
  color: black;
  width: 12px;
}

.Lbs45 {
  background: linear-gradient(#3d3d3d, #747474);
  height: 200px;
}

.Lbs35 {
  background: linear-gradient(#3d3d3d, #747474);
  height: 180px;
}

.Lbs25 {
  background: linear-gradient(#3d3d3d, #747474);
  height: 120px;
}

.Lbs10 {
  background: linear-gradient(#3d3d3d, #747474);
  height: 60px;
}

.Lbs5 {
  background: linear-gradient(#3d3d3d, #747474);
  height: 50px;
}

.LbsTwoPointFive {
  background: linear-gradient(#3d3d3d, #747474);
  height: 30px;
}
