.PlateCalculator {
  margin-left: auto;
  margin-right: auto;
}

.Label {
  display: block;
}

.Button {
  display: block;
}

.WeightInputContainer {
  display: grid;
  grid-template-columns: auto auto;
}

.WeightInputContainer > div {
  text-align: center;
  padding: 20px 20px;
  font-size: 30px;
}

.NavBar {
  font-family: 'Chango', cursive;
  font-size: 50px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

.WeightDisplay {
  position: relative;
}

.DisplayedWeight {
  font-size: 14px;
  margin-bottom: 20px;
}

.ButtonGridContainer {
  display: grid;
  grid-auto-flow: column;
}

.BarLabel {
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  text-align: center;
}

.BarLabel:before,
.BarLabel:after {
  background-color: rgb(255, 255, 255);
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}
.BarLabel:before {
  right: 0.5em;
}
.BarLabel:after {
  left: 0.5em;
}

.PlateContainer {
  margin-left: 105px;
  width: 0px;
  height: 200px;
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
  .PlateCalculator {
    width: 800px;
  }
}
