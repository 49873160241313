.WeightLabel {
  display: block;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

.WeightInput {
  padding: 3% 15%;
  width: 100px;
  font-size: 25px;
  border-radius: 8px;
  text-align: center;
}

@media (max-width: 600px) {
  .WeightInput {
    padding: 3% 10%;
  }
}
