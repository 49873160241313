.Button {
  background-color: #5c9210;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font: inherit;
  font-size: 23px;
  padding: 10px;
  margin: 10px;
  font-weight: bolder;
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
}

.Button:disabled {
  color: white;
  cursor: not-allowed;
}

.ToggleUnits {
  background-color: #19456b;
  box-shadow: 0 2px 4px rgb(36, 36, 36);
  width: 80%;
}

.Bar {
  color: white;
  background-color: #16c79a;
  box-shadow: 0 2px 3px rgb(48, 47, 47);
}

.Active {
  background-color: #109b78;
  border: 3px solid white;
}

@media (max-width: 600px) {
  .Button {
    font-size: 18px;
  }
}
